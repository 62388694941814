@import 'styles/common.scss';

.container {
  @include flex-column;
  gap: 20px;
  .top {
    @include flex;
    column-gap: 12px;
    .name-address {
      @include flex-column(flex-start, space-between);
      padding: 10px 0;
      gap: 4px;
      .name {
        @include ui-heading-h1;
      }
    }
  }
  .address {
    display: none;
  }
  .location,
  .address {
    @include ui-text-ui-13-medium;
    color: var(--text-icon-tertiary);
  }
  .description {
    @include ui-paragraph-16-regular;
  }

  @include media-breakpoint-down(b768) {
    padding: 24px 16px;
    border-bottom: 1px solid var(--border-border-10);
    &.has-cover {
      padding: 0 16px 24px;
      position: relative;
      top: -40px;
      margin-bottom: -40px;
      gap: 0;
      .top {
        @include flex-column;
        gap: 20px;

        .name-address {
          padding: 0;
        }
      }
      .location {
        display: none;
      }
      .description {
        padding: 4px 0;
      }
      .address {
        display: block;
        margin: 4px 0;
      }
    }
    .top {
      .name-address {
        .name {
          @include ui-heading-h2;
        }
      }
    }
  }
  @include media-breakpoint-up(b768) {
    .top {
      .name-address {
        .name {
          @include ui-heading-h1;
        }
      }
    }
  }
}
