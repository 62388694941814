@import 'styles/common.scss';

.opportunity-card {
  border: 1px solid var(--border-border-10, #080c0b1a);
  cursor: pointer;
}

.ended-events-banner {
  @include flex;
  @include ui-text-ui-14-regular;
  column-gap: 10px;
  width: 100%;
  padding: 16px 20px;
  background-color: var(--surface-overlay-silent);
  color: var(--text_icon-secondary);
  border: 0.5px solid var(--border-inverted-20);
  border-radius: 16px;
}

.past-events-header {
  @include flex-center;
  padding: 16px 20px;
  column-gap: 12px;
  margin: 8px 0;
  .title {
    @include ui-text-ui-12-17-medium;
    color: var(--text-icon-tertiary);
  }
  .divider {
    border-top: 1px solid var(--border-border-10);
    flex: 1;
  }
}

.pagination {
  @include flex(center);
  column-gap: 8px;
  padding: 6px 0;
  margin-top: 16px;
  .showing {
    @include ui-text-ui-14-medium;
    color: var(--text-icon-tertiary);
  }
  .show-more {
    @include ui-text-ui-14-medium;
    color: var(--text-icon-interactive);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
}
