@import 'styles/common.scss';

.container {
  @include flex(flex-start);
  background: var(--ui-teal-light-bg-primary);
  padding: 20px;
  border-radius: 16px;
  column-gap: 12px;
  box-shadow: 0px 1px 3px 0px #080C0B0D;
  &:hover {
    background: var(--ui-teal-light-surface-tertiary-hover);
  }
  .main {
    @include flex-column;
    flex: 1;
    gap: 12px;
    .head {
      @include flex-column;
      gap: 2px;
      .tags {
        @include flex;
        column-gap: 4px;
        .tag {
            @include ui-text-ui-11-medium;
            padding: 2px 6px;
            border-radius: 4px;
            background-color: var(--ui-teal-light-surface-brand-silent);
            color: var(--primary-color);
            align-self: flex-start;
        }
      }
      .event-time {
        @include ui-text-ui-13-semibold;
        color: var(--text_icon-on_lights-critical);
      }
      .title {
        @include ui-text-ui-16-semibold;
      }
      &.ended {
        .event-time, .title {
          color: var(--text-icon-tertiary);
        }
      }
    }
    .labels {
      @include flex(center);
      flex-wrap: wrap;
      gap: 4px;
      .ended-chip {
        @include flex-center;
        column-gap: 5px;
      }
    }
    .info {
      @include flex;
      @include ui-text-ui-13-medium;
      color: var(--text-icon-tertiary);
      .dot {
        font-size: 6px;
        margin: 0 4px;
      }
    }
    .applications {
      padding-top: 8px;
    }
  }
  .event-banner {
    width: 64px;
    border-radius: 6px;
    border: 0.5px solid rgba(8, 12, 11, 0.10);

    &.grayscaled {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }
}
