@import 'styles/common.scss';

.container {
  width: 100%;
  padding: 20px 0 20px 20px;
  background: var(--border-on-lights-03);
  border: 0.5px solid var(--border-on-lights-1);
  border-radius: 16px;

  .head {
    @include flex(center, space-between);
    width: 100%;
    padding-bottom: 16px;

    .title {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      color: var(--text-primary-v2);
      padding: 2px 0;
    }

    .view-all {
      @include f-inter-regular-2(1.3rem, 1.8rem);
      @include flex(center);
      column-gap: 5px;
      padding-right: 20px;
      color: var(--text-icon-interactive);
      cursor: pointer;
    }
  }

  .navigation {
    width: 80px !important;
    background: transparent;

    button {
      border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
      box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);

      &:hover {
        background: var(--ui_teal_light-surface-primary-hover);
        border-radius: 50%;
      }
    }
  }

  @include media-breakpoint-down(b1100) {
    border-radius: 0;
    padding-bottom: 48px;

    .cards-container {
      @include flex;
      @include hide-scrollbar();
      flex-wrap: nowrap;
      column-gap: 16px;
      max-width: 100vw;
      padding: 0 20px;
      margin: 0 -20px;
      overflow-x: auto;
    }
  }
}
