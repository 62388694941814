@use "sass:math";

@import 'styles/common.scss';

.container {
  @include flex-column;
  .navigation-container {
    position: sticky;
    z-index: $zindex-sticky;
    background-color: var(--ui-teal-light-bg-primary);
    border-bottom: 1px solid var(--border-border-10);
    margin-bottom: -1px;
  }
  .tab-list {
    @include flex;
    column-gap: 12px;
    overflow-y: hidden;
    .tab {
      @include flex-center;
      position: relative;
      top: 1px;
      color: var(--text-icon-tertiary);
      background-color: transparent;
      border-left: none;
      border-right: none;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      white-space: nowrap;
      column-gap: 8px;
      .badge-count {
        @include ui-text-ui-11-medium;
        background-color: var(--surface-overlay-silent);
        color: var(--text-icon-tertiary);
        padding: 2px 6px;
        border-radius: 100px;
      }
      &:hover {
        cursor: pointer;
      }
      &.selected {
        color: var(--text-primary-v2);
        border-bottom-color: var(--primary-color);
      }
      @include media-breakpoint-down(b768) {
        @include ui-text-ui-14-semibold;
        padding: 11px 12px;
      }
      @include media-breakpoint-up(b768) {
        @include ui-text-ui-16-semibold;
        padding: 13px 8px;
      }
    }
  }
  .tab-panels {
    @include flex-column;
    padding: 8px 0;
    @include mobile {
      padding-bottom: 0px;
    }

    .tab-panel {
      @include flex-column;
      gap: 8px;
      scroll-margin-top: 56px;
      @include mobile {
        scroll-margin-top: 90px;
        &.signin-header {
          scroll-margin-top: 40px;
        }
      }
      .title {
        @include ui-heading-h2;
        margin-bottom: 8px;
      }
      .divider {
        margin: 16px 0;
      }
      .view-all {
        align-self: flex-start;
        margin-top: 8px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .opportunity-card {
    border: 1px solid var(--border-border-10, #080c0b1a);
    cursor: pointer;
  }
  @include media-breakpoint-down(b768) {
    .navigation-container {
      border-top: 1px solid var(--border-border-10);
      top: -1px;
      z-index: $zindex-sticky;
      &.no-global-header {
        top: 55px;
      }
      &.below-banner {
        top: -1px;
      }
      &.is-stickied {
        .tab-list {
          box-shadow: 0px 1px 3px 0px #080c0b0d;
        }
      }
    }
    .tab-list {
      @include hide-scrollbar;
      background-color: var(--ui-teal-light-bg-primary);
      overflow-x: auto;
      max-width: 100vw;
      padding: 0 16px 0 32px;
      margin: 0 -16px;
      box-shadow: 0px 1px 2px 0px #09142508;
    }
    .tab-panel {
      padding: 24px 16px;
      border-bottom: 1px solid var(--border-border-10);
      .view-all {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(b768) {
    .navigation-container {
      top: -1px;
      & ~ .tab-panels {
        .tab-panel {
          padding: 24px 0;
          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }
    }
    .tab {
      &:hover {
        color: var(--text-primary-v2);
        border-bottom-color: var(--primary-color);
      }
    }
  }
  @include media-breakpoint-down(b1100) {
    .organization-actions {
      display: none;
    }
  }
  @include media-breakpoint-up(b1100) {
    .navigation-container {
      @include flex(center, space-between);
      &.is-stickied {
        $left-page-margin: #{calc(-50vw + #{$company-page-content-width / 2})};
        $right-page-margin: #{calc(-50vw + #{$company-page-content-width / 2} - #{$company-page-sidebar-width + $company-page-content-gap})};
        $page-padding: #{calc(50vw - #{$company-page-content-width / 2})};
        margin: 0 $right-page-margin 0 $left-page-margin;
        padding: 0 $page-padding;
      }
      &:not(.is-stickied) {
        .organization-actions {
          display: none;
        }
      }
    }
  }
}
