@import 'styles/common.scss';

@include media-breakpoint-down(b768) {
  .content-section {
    padding: 24px 16px;
    border-top: 1px solid var(--border-border-10);
    .title {
      @include ui-heading-h2;
      margin-bottom: 16px;
      color: var(--text_icon-primary);
    }
  }
}

@include media-breakpoint-up(b768) {
  .content-section {
    padding: 16px;
    .title {
      @include ui-text-ui-16-20-semibold;
      margin-bottom: 12px;
      color: var(--text-icon-tertiary);
    }
  }
}
