@import 'styles/common.scss';

.container {
  @include flex-column(center, space-between);
  width: 232px;
  height: 188px;
  padding: 20px;
  border-radius: 8px;
  background: var(--bg-primary);
  box-shadow: 0px 1px 3px 0px var(--surface-overlay-silent);
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;

  .avatar {
    cursor: pointer;
  }

  .info {
    .title {
      @include f-inter-semi-bold(1.4rem, 2rem);
      @include multi-line-ellipsis(2);
      color: var(--text-primary-v2);
      width: 100%;
      word-break: break-word;
    }
    .subtitle {
      @include f-inter-regular(1.2rem, 1.7rem);
      @include multi-line-ellipsis(2);
      color: var(--text-icon-on-lights-tertiary);
      width: 100%;
      word-break: break-word;
    }
  }
}
