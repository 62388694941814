@import 'styles/common.scss';

.container {
  position: relative;
  @include media-breakpoint-down(b768) {
    @include flex-column(flex-start);
    .header {
      @include flex(center, space-between);
      position: fixed;
      top: 0;
      width: 100%;
      padding: 8px 14px;
      z-index: $zindex-fixed;
      .hamburger {
        position: relative;
        &-trigger {
          border-radius: 50%;
        }
        &-list {
          top: 48px;
          right: unset;
          left: 0;
        }
      }
      .avatar {
        display: none;
      }
      .dummy {
        height: 40px;
        width: 40px;
      }
      &.has-bg {
        background-color: var(--ui-teal-light-bg-primary);
        .avatar {
          display: block;
        }
      }
    }
    .cover {
      width: 100%;
      .banner {
        width: 100%;
        object-fit: cover;
        min-height: 160px;
        max-height: 200px;
      }
    }
    .content {
      @include flex-column(flex-start);
      .main,
      .sidebar {
        width: 100%;
      }
      &.fixed-header {
        padding-top: 56px;
      }
      .main {
        .head {
          padding: 24px 16px;
          border-bottom: 1px solid var(--border-border-10);
          &.shift-up {
            padding: 0 16px 24px;
            position: relative;
            top: -40px;
            margin-bottom: -40px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(b768) {
    @include flex-column(flex-start);
    margin: 0 auto;
    flex: 1;
    padding: 32px 0;
    gap: 48px;
    .header {
      display: none;
    }
    .cover {
      width: $company-page-content-width;
      .banner {
        width: 100%;
        border-radius: 16px;
        height: 280px;
        border: 0.5px solid #080c0b1a;
        object-fit: cover;
        min-height: 280px;
        max-height: 360px;
      }
    }
    .content {
      @include flex(flex-start);
      flex: 1;
      column-gap: $company-page-content-gap;
      .main {
        @include flex-column;
        gap: 48px;
        width: $company-page-main-content-width;
        padding-bottom: 48px;
      }
      .sidebar {
        width: $company-page-sidebar-width;
      }
    }
  }
  @include media-breakpoint-between(b768, b1100) {
    .cover,
    .content .main,
    .content .sidebar {
      width: $company-page-main-content-width;
      max-width: $company-page-main-content-width;
    }
    .content {
      @include flex-column(flex-start);
    }
  }
}
