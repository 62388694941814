@import 'styles/common.scss';

.page {
    background-color: var(--ui-teal-light-bg-secondary);
    flex: 1;
    overflow-y: auto;
    max-height: 100vh;
    max-height: 100dvh;

    &.header-only {
        @include media-breakpoint-small {
            max-height: calc(100vh - #{$public-pages-header-height-sm});
            max-height: calc(100dvh - #{$public-pages-header-height-sm});

            &.has-banner-above {
                max-height: calc(100vh - #{$public-pages-header-height-sm} - #{$public-page-banner-height});
                max-height: calc(100dvh - #{$public-pages-header-height-sm} - #{$public-page-banner-height});
            }
        }
        @include media-breakpoint-small-up {
            max-height: calc(100vh - #{$public-pages-header-height-lg});
            max-height: calc(100dvh - #{$public-pages-header-height-lg});

            &.has-banner-above {
                max-height: calc(100vh - #{$public-pages-header-height-lg} - #{$public-page-banner-height});
                max-height: calc(100dvh - #{$public-pages-header-height-lg} - #{$public-page-banner-height});
            }
        }
    }
    &.footer-only {
        max-height: calc(100vh - #{$footer-height});
        max-height: calc(100dvh - #{footer-height});

        &.has-banner-above {
            max-height: calc(100vh - #{$footer-height} - #{$public-page-banner-height});
            max-height: calc(100dvh - #{$footer-height} - #{$public-page-banner-height});
        }
    }
    &.header-and-footer {
        max-height: calc(100vh - #{$public-pages-header-height-lg} + #{$footer-height});
        max-height: calc(100dvh - #{$public-pages-header-height-lg} + #{$footer-height});

        &.has-banner-above {
            max-height: calc(100vh - #{$public-pages-header-height-lg} + #{$footer-height} - #{$public-page-banner-height});
            max-height: calc(100dvh - #{$public-pages-header-height-lg} + #{$footer-height} - #{$public-page-banner-height});
        }
    }
}
